export const AVAILABLE_CURRENCIES = [
  'USD',
  'CAD',
  'IDR',
  'GBP',
  'CHF',
  'SGD',
  'INR',
  'MYR',
  'JPY',
  'KRW',
]